import React, {Fragment, useState} from 'react';
import TextField from '@mui/material/TextField';
import {InputLabel} from '@mui/material';
import styles from './CommerceCloudForm.module.css';
import {Button} from '@mui/material';
import {useDispatch} from 'react-redux';
import {addCommerceCloudOrg} from 'redux/actions';
import PropTypes from 'prop-types';

function CommerceCloudForm({closeModal}) {
  const [commerceCloudForm, setCommerceCloudForm] = useState({
    name: '',
    clientId: '',
    clientSecret: '',
    username: '',
    accesskey: '',
    instanceUrl: '',
  });

  const dispatch = useDispatch();

  const textChangeHandler = (event) => {
    setCommerceCloudForm((cur) => {
      return {
        ...cur,
        [event.target.name]: event.target.value,
      };
    });
  };

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const clickHandler = (event) => {
    event.preventDefault();
    const instanceUrl = commerceCloudForm.instanceUrl;
    const mySalesforceRegex = /^https:\/\/[\w.-]*salesforce\.com\/?$/;
    if (mySalesforceRegex.test(instanceUrl)) {
      dispatch(addCommerceCloudOrg(commerceCloudForm));
    } else {
      setError(true);
      setErrorMessage('The instance URL should be in the format of *.salesforce.com');
    }
  };

  return (
    <Fragment>
      <form onSubmit={clickHandler} className={styles.formHeight}>
        <div className={styles.inputContainer}>
          <InputLabel className={styles.labelInput}>Workspace Name</InputLabel>
          <TextField
            id='filled-basic'
            label='Name'
            variant='filled'
            placeholder='Name to Remember your Salesforce'
            name='name'
            value={commerceCloudForm.name}
            onChange={textChangeHandler}
            sx={{
              width: {xs: '100%', sm: '100%', md: '420px'},
            }}
            required
          ></TextField>
        </div>

        <div className={styles.inputContainer}>
          <InputLabel className={styles.labelInput}>API Client ID</InputLabel>
          <TextField
            id='filled-basic'
            label='API Client Id'
            variant='filled'
            placeholder='API Client Id'
            name='clientId'
            value={commerceCloudForm.clientId}
            onChange={textChangeHandler}
            sx={{
              width: {xs: '100%', sm: '100%', md: '420px'},
            }}
            required
          ></TextField>
        </div>

        <div className={styles.inputContainer}>
          <InputLabel className={styles.labelInput}>
            API Client Password
          </InputLabel>
          <TextField
            type='password'
            id='filled-basic'
            label='API Client Password'
            variant='filled'
            placeholder='Client Secret for your API Client'
            name='clientSecret'
            sx={{
              width: {xs: '100%', sm: '100%', md: '420px'},
            }}
            onChange={textChangeHandler}
            value={commerceCloudForm.clientSecret}
            required
          ></TextField>
        </div>

        <div className={styles.inputContainer}>
          <InputLabel className={styles.labelInput}>
            Business Manager Username
          </InputLabel>
          <TextField
            id='filled-basic'
            label='Business Manager Username'
            variant='filled'
            placeholder='Business Manager Username'
            name='username'
            sx={{
              width: {xs: '100%', sm: '100%', md: '420px'},
            }}
            onChange={textChangeHandler}
            value={commerceCloudForm.username}
            required
          ></TextField>
        </div>

        <div className={styles.inputContainer}>
          <InputLabel className={styles.labelInput}>
            Business Manager Password
          </InputLabel>
          <TextField
            type='password'
            id='filled-basic'
            label='Business Manager Password'
            variant='filled'
            placeholder='Business Manager Password'
            name='accesskey'
            onChange={textChangeHandler}
            sx={{
              width: {xs: '100%', sm: '100%', md: '420px'},
            }}
            value={commerceCloudForm.accesskey}
            required
          ></TextField>
        </div>

        <div className={styles.inputContainer}>
          <InputLabel className={styles.labelInput}>Instance URL</InputLabel>
          <TextField
            id='filled-basic'
            label='Instance URL'
            variant='filled'
            placeholder='Instance URL'
            name='instanceUrl'
            error={error}
            helperText={error ? errorMessage : ''}
            sx={{
              width: {xs: '100%', sm: '100%', md: '420px'},
            }}
            value={commerceCloudForm.instanceUrl}
            onChange={textChangeHandler}
            required
          ></TextField>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
            paddingBottom: '10px',
          }}
        >
          <Button variant='contained' color='secondary' onClick={closeModal}>
            Cancel
          </Button>
          <Button variant='contained' type='submit'>
            Save
          </Button>
        </div>
      </form>
    </Fragment>
  );
}

export default CommerceCloudForm;

CommerceCloudForm.propTypes = {
  closeModal: PropTypes.func,
};
