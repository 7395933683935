import React, {Fragment, useState} from 'react';
import TextField from '@mui/material/TextField';
import {InputLabel} from '@mui/material';
import styles from './EditSfcc.module.css';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {EDIT_SALESFORCE_ORG_RESULT} from 'shared/constants/ActionTypes';
import ModalContainer from 'components/modals/ModalContainer/ModalContainer';
import {updateCommerceCloudOrg} from 'redux/actions';
import UpdateAgain from '../UpdateAgain';
import {Button} from '@mui/material';

function EditSfcc({name, instanceurl, handleModalClose, open, org_id}) {
  const [commerceCloudForm, setCommerceCloudForm] = useState({
    name: name,
    clientId: '',
    clientSecret: '',
    username: '',
    accesskey: '',
    instanceUrl: instanceurl,
  });

  const dispatch = useDispatch();

  const addingOrgInProcess = useSelector(
    ({salesforce}) => salesforce.addingOrgInProcess,
  );
  const editSalesforceOrgResult = useSelector(
    ({salesforce}) => salesforce.editSalesforceOrgResult,
  );

  const textChangeHandler = (event) => {
    setCommerceCloudForm((cur) => {
      return {
        ...cur,
        [event.target.name]: event.target.value,
      };
    });
  };

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const clickHandler = (event) => {
    event.preventDefault();
    const instanceUrl = commerceCloudForm.instanceUrl;
    const mySalesforceRegex = /^https:\/\/[\w.-]*salesforce\.com\/?$/;
    if (mySalesforceRegex.test(instanceUrl)) {
      dispatch(updateCommerceCloudOrg({...commerceCloudForm, org_id}));
    } else {
      setError(true);
      setErrorMessage('The instance URL should be in the format of *.salesforce.com');
    }
  };

  const sfccModalClose = () => {
    dispatch({type: EDIT_SALESFORCE_ORG_RESULT, payload: 'fail'});
    handleModalClose();
  };

  return (
    <Fragment>
      <ModalContainer
        handleModalClose={sfccModalClose}
        className={styles.formContainer}
        title='Edit SFCC'
        open={open}
      >
        {addingOrgInProcess == false && editSalesforceOrgResult == null && (
          <div className={styles.mainContainer}>
          <form onSubmit={clickHandler}>
            <div className={styles.inputContainer}>
              <InputLabel className={styles.labelInput}>
                Workspace Name
              </InputLabel>
              <TextField
                id='filled-basic'
                label='Name'
                variant='filled'
                placeholder='Name to Remember your Salesforce'
                name='name'
                value={commerceCloudForm.name}
                onChange={textChangeHandler}
                className={styles.inputTextfield}
                required
              ></TextField>
            </div>

            <div className={styles.inputContainer}>
              <InputLabel className={styles.labelInput}>
                API Client ID
              </InputLabel>
              <TextField
                id='filled-basic'
                label='API Client Id'
                variant='filled'
                placeholder='API Client Id'
                name='clientId'
                value={commerceCloudForm.clientId}
                onChange={textChangeHandler}
                className={styles.inputTextfield}
                required
              ></TextField>
            </div>

            <div className={styles.inputContainer}>
              <InputLabel className={styles.labelInput}>
                API Client Password
              </InputLabel>
              <TextField
                type='password'
                id='filled-basic'
                label='API Client Password'
                variant='filled'
                placeholder='Client Secret for your API Client'
                name='clientSecret'
                className={styles.inputTextfield}
                onChange={textChangeHandler}
                value={commerceCloudForm.clientSecret}
                required
              ></TextField>
            </div>

            <div className={styles.inputContainer}>
              <InputLabel className={styles.labelInput}>
                Business Manager Username
              </InputLabel>
              <TextField
                id='filled-basic'
                label='Business Manager Username'
                variant='filled'
                placeholder='Business Manager Username'
                name='username'
                className={styles.inputTextfield}
                onChange={textChangeHandler}
                value={commerceCloudForm.username}
                required
              ></TextField>
            </div>

            <div className={styles.inputContainer}>
              <InputLabel className={styles.labelInput}>
                Business Manager Password
              </InputLabel>
              <TextField
                type='password'
                id='filled-basic'
                label='Business Manager Password'
                variant='filled'
                placeholder='Business Manager Password'
                name='accesskey'
                onChange={textChangeHandler}
                className={styles.inputTextfield}
                value={commerceCloudForm.accesskey}
                required
              ></TextField>
            </div>

            <div className={styles.inputContainer}>
              <InputLabel className={styles.labelInput}>
                Instance URL
              </InputLabel>
              <TextField
                id='filled-basic'
                label='Instance URL'
                variant='filled'
                placeholder='Instance URL'
                name='instanceUrl'
                className={styles.inputTextfield}
                value={commerceCloudForm.instanceUrl}
                onChange={textChangeHandler}
                error={error}
                helperText={error ? errorMessage : ''}
                required
                FormHelperTextProps={{
                  style: {
                    fontSize: '12px',
                    marginRight: '0px',
                    marginLeft: '0px',
                  },
                }}
              ></TextField>
            </div>
            <div className={styles.btnContainer}>
              <Button
                onClick={sfccModalClose}
                variant='contained'
                color='secondary'
              >
                Close
              </Button>
              <Button type='submit' variant='contained'>
                Update
              </Button>
            </div>
          </form>
          </div>
        )}
        {/* {addingOrgInProcess && <ProcessLoader />} */}
        <UpdateAgain close='Close' handleModalClose={handleModalClose} />
      </ModalContainer>
    </Fragment>
  );
}

export default EditSfcc;

EditSfcc.propTypes = {
  name: PropTypes.string,
  instanceurl: PropTypes.string,
  handleModalClose: PropTypes.func,
  open: PropTypes.bool,
  org_id: PropTypes.string,
};
