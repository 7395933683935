import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import {useDispatch} from 'react-redux';
import styles from './AddUser.module.css';
import {Button} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useSelector} from 'react-redux';
import {onPostAddUser} from 'redux/actions';
import OrgRoleForm from './AssignRoles/OrgRoleForm/OrgRoleForm';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {FormControl, MenuItem, Select, Grid} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {FETCH_ERROR} from 'shared/constants/ActionTypes';
import {assignRole} from 'redux/actions/Roles';
import {onPostEditUserSfdc} from 'redux/actions';
import {useParams} from 'react-router-dom';

const AddUser = ({
  addModalClose,
  title,
  user,
  editMode,
  userGlobalRole,
  teamMenu,
  deletedUserModal,
  hidden,
}) => {
  let {org_id} = useParams();
  const dispatch = useDispatch();
  const [showOrgRows, setShowOrgRows] = useState(true);
  const [userForm, setUserForm] = useState({
    role: '',
    email: '',
    firstname: '',
    lastname: '',
  });
  const [orgRole, setOrgRole] = useState([]);
  const allRoles = useSelector(({roles}) => roles.getAllRoles);
  const clonedOrgRoles = allRoles?.filter((item) => item.type === 'ORG_LEVEL');
  const clonedGlobalRoles = allRoles?.filter((item) => item.type === 'GLOBAL');
  const getAllOrgsInAccount = useSelector(({setup}) => setup.getAllOrgsInAccount);
  const [globalRole, setGlobalRole] = useState({
    roleId: userGlobalRole?._id || '',
    flag: userGlobalRole?.type || '',
  });
  const onChangeForm = (event) => {
    setUserForm((cur) => {
      return {
        ...cur,
        [event.target.name]: event.target.value,
      };
    });
  };

  useEffect(() => {
    let assignedOrg = [];
    user?.salesforceOrgs
      ?.filter((item) => item.access === true)
      .map((item) => {
        if (item.roleId) {
          assignedOrg.push({
            sfdcId: item.sfdcId,
            roleId: item.roleId,
          });
        } else if (
          user.role !== 'NO_ROLE' &&
          user.permissions !== 'NO_PERMISSIONS'
        ) {
          if (user.role === 'admin' && user.permissions === 'write') {
            assignedOrg.push({
              sfdcId: item.sfdcId,
              roleId: allRoles?.find(
                (item) => item.name === 'Workspace Manager',
              )._id,
            });
          } else if (user.role === 'user' && user.permissions === 'write') {
            assignedOrg.push({
              sfdcId: item.sfdcId,
              roleId: allRoles?.find(
                (item) => item.name === 'Workspace Manager',
              )._id,
            });
          } else {
            assignedOrg.push({
              sfdcId: item.sfdcId,
              roleId: allRoles?.find(
                (item) => item.name === 'Workspace User Read Only',
              )._id,
            });
          }
        }
        return;
      });
    setOrgRole(assignedOrg);

    return () => {
      setOrgRole([]);
    };
  }, []);

  const onOrgSelectChange = () => {};

  /// global

  const getGlobalRole = (id) => {
    return clonedGlobalRoles[
      clonedGlobalRoles.findIndex((role) => role._id === id)
    ];
  };

  const onRoleChnage = (event) => {
    const {value} = event.target;
    if (!value) return;
    const role = getGlobalRole(value);
    if (role.name === 'Admin' && !editMode) {
      setShowOrgRows(false);
    } else {
      setShowOrgRows(true);
    }
    setGlobalRole({
      roleId: role._id,
      flag: role.type,
    });
  };

  /// ORG ROLE

  const validateOrgRole = (orgRoleArr) => {
    return (
      orgRoleArr.length ===
      orgRoleArr.filter((cur) => cur.roleId && cur.sfdcId).length
    );
  };

  const addOrgRoleHandler = () => {

    setOrgRole((cur) => {
      return [
        {
          sfdcId: '',
          roleId: '',
        },
        ...cur,
      ];
    });
  };
  


  const onSelectChange = (event, index) => {
    setOrgRole((cur) => {
      let updateTag = [...cur];
      updateTag[index][event.target.name === 'sfdc' ? 'sfdcId' : 'roleId'] =
        event.target.value;
      return [...updateTag];
    });
  };

  const removeAssignRole = (index) => {
    setOrgRole((cur) => {
      let updateTag = [...cur];
      updateTag.splice(index, 1);
      return [...updateTag];
    });
  };

  // --------------------------------------Submit DATA ------------------//

  const createSFDCPayload = (orgRoles) => {
    let SFDCID = orgRoles.map((orgRole) => orgRole.sfdcId);

    let selectedORG = getAllOrgsInAccount
      ?.filter((org) => SFDCID?.includes(org?._id))
      .map((org) => `${org?.name}/*/*/${org?._id}`);

    return selectedORG;
  };

  const addUser = (event) => {
    event.preventDefault();
    let userAdd = {
      email: userForm.email,
      firstname: userForm.firstname,
      lastname: userForm.lastname,
      role: 'NO_ROLE',
      permissions: 'NO_PERMISSIONS',
      is_sfdc_oauth: false,
      method: 'create',
    };

    let globalRolePayload =
      {
        flag: 'GLOBAL',
        method: 'assign',
        roleId: globalRole.roleId || '',
      } || {};

    let orgRolePayload = orgRole.length
      ? {
          method: 'assign',
          orgs: orgRole,
          flag: 'ORG_LEVEL',
        }
      : null;

    if (orgRole.length > 0 && !validateOrgRole(orgRole)) {
      dispatch({
        type: FETCH_ERROR,
        payload: 'Please select both Org Name and Org Role from the dropdowns!',
      });
      return;
    }

    let editSFDCPayload = {
      orgName: createSFDCPayload(orgRole) || [],
    };

    if (!editMode) {
      dispatch(
        onPostAddUser({
          userAdd,
          globalRolePayload,
          orgRolePayload,
          editSFDCPayload,
          org_id,
        }),
      );
    } else {
      if (editSFDCPayload.length !== 0) {
        if (!orgRolePayload) {
          dispatch(
            onPostEditUserSfdc({
              ...editSFDCPayload,
              userId: user._id,
              roleIdUser: user.roleId,
              orgId: org_id,
              org_id: org_id,
            }),
          );
        } else {
          dispatch(
            onPostEditUserSfdc({
              userId: user._id,
              ...editSFDCPayload,
              org_id: org_id,
              globalRolePayload: globalRolePayload,
              orgRolePayload: orgRolePayload,
              flag: 'GLOBAL_AND_ORG_LEVEL',
              isUserEdit: true,
              // roleIdUser: user.roleId,
              // userEmail: user.email,
            }),
          );
        }
      }

      // if (orgRolePayload) {
      //   dispatch(
      //     assignRole({
      //       ...globalRolePayload,
      //       ...orgRolePayload,
      //       flag: 'GLOBAL_AND_ORG_LEVEL',
      //       userId: user._id,
      //       roleIdUser: user.roleId,
      //       userEmail: user.email,
      //       orgId: org_id,
      //     }),
      //   );
      // } else {
      if (!orgRolePayload) {
        dispatch(
          assignRole({
            ...globalRolePayload,
            userId: user._id,
            roleIdUser: user.roleId,
            userEmail: user.email,
            orgId: org_id,
          }),
        );
      }
    }
    addModalClose();
  };

  //---------------------------------------------------------//
  return (
    <>
      <div className={styles.titleContainer}>
        <GroupAddIcon color='primary' sx={{fontSize: 30}} />
        <h1>{title || 'Add User'}</h1>
      </div>
      <form onSubmit={addUser}>
        <div className={styles.formContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <div className={styles.fieldContainer}>
                <h4 className={styles.fieldLabel}>
                  User Email<span style={{color: 'red'}}>*</span>
                </h4>
                <TextField
                  fullWidth
                  required
                  id='add-user-email'
                  variant='outlined'
                  type='email'
                  name='email'
                  placeholder='Email'
                  value={editMode ? user.email : userForm.email}
                  onChange={onChangeForm}
                  disabled={editMode}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div className={styles.fieldContainer}>
                <h4 className={styles.fieldLabel}>
                  First Name<span style={{color: 'red'}}>*</span>
                </h4>
                <TextField
                  fullWidth
                  required
                  id='add-user-firstname'
                  variant='outlined'
                  type='text'
                  name='firstname'
                  placeholder='First name'
                  value={
                    editMode ? user?.name?.split(' ')[0] : userForm?.firstname
                  }
                  onChange={onChangeForm}
                  disabled={editMode}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div className={styles.fieldContainer}>
                <h4 className={styles.fieldLabel}>
                  Last Name<span style={{color: 'red'}}>*</span>
                </h4>
                <TextField
                  fullWidth
                  required
                  id='add-user-lastname'
                  variant='outlined'
                  type='text'
                  name='lastname'
                  placeholder='Last name'
                  value={
                    editMode ? user?.name?.split(' ')[1] : userForm.lastname
                  }
                  onChange={onChangeForm}
                  disabled={editMode}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div className={styles.roleDropdown}>
                <FormControl sx={{width: '100%'}}>
                  <h4 className={styles.fieldLabel}>
                    Environment Role<span style={{color: 'red'}}>*</span>
                  </h4>
                  <Select
                    fullWidth
                    id='globalRole'
                    name='globalRole'
                    onChange={(event) => onRoleChnage(event)}
                    required
                    displayEmpty
                    inputProps={{'aria-label': 'Without label'}}
                    value={globalRole?.roleId}
                    defaultValue={globalRole?.roleId || ''}
                    disabled={deletedUserModal || teamMenu}
                  >
                    <MenuItem value=''>
                      <em>Select</em>
                    </MenuItem>
                    {clonedGlobalRoles?.map((list) => (
                      <MenuItem value={list._id} key={list?._id}>
                        {list?.name?.replaceAll('_', ' ')}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>
          </Grid>
          {showOrgRows && (
            <div className={styles.addOrgRoleHeader} style={{ display: hidden ? 'none' : 'block' }}>
              <Button
                variant='contained'
                onClick={addOrgRoleHandler}
                startIcon={<AddIcon />}
              >
                Add Workspace Role
              </Button>
            </div>
          )}
          {showOrgRows && (
            <div>
              <TableContainer component={Paper}>
                <Table
                  sx={{minWidth: 650}}
                  aria-label='simple table'
                  stickyHeader={true}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align='left'>Workspaces</TableCell>
                      <TableCell align='left'>Roles</TableCell>
                      {!deletedUserModal && (
                      <TableCell align='left'>Remove</TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody /*sx={{maxHeight: 500, overflow: 'scroll'}}*/>
                    {orgRole?.map((item, index) => {
                      return !teamMenu ? (
                        <OrgRoleForm
                          orgsSelected={orgRole}
                          key={index}
                          onSelectChange={onSelectChange}
                          index={index}
                          removeAssignRole={removeAssignRole}
                          sfdcInfo={getAllOrgsInAccount}
                          clonedOrgRoles={clonedOrgRoles}
                          onOrgSelectChange={onOrgSelectChange}
                          sfdcId={item.sfdcId}
                          roleId={item.roleId}
                          deletedUserModal={deletedUserModal}
                          hidden={hidden}
                        />
                      ) : item.sfdcId === org_id ? (
                        <OrgRoleForm
                          key={index}
                          onSelectChange={onSelectChange}
                          index={index}
                          removeAssignRole={removeAssignRole}
                          sfdcInfo={getAllOrgsInAccount}
                          clonedOrgRoles={clonedOrgRoles}
                          onOrgSelectChange={onOrgSelectChange}
                          sfdcId={item.sfdcId}
                          roleId={item.roleId}
                          teamMenu={teamMenu}
                          deletedUserModal={deletedUserModal}
                          hidden={hidden}
                        />
                      ) : (
                        ''
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}

          <div className={styles.formActionContainer}>
            <Button
              variant='contained'
              color='secondary'
              onClick={addModalClose}
            >
              Close
            </Button>
            <Button
              style={{ display: hidden ? 'none' : 'block' }}
              variant='contained'
              type='submit'
              disabled={deletedUserModal}
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

AddUser.propTypes = {
  addModalClose: PropTypes.func,
  orgName: PropTypes.string,
  org_id: PropTypes.string,
  userId: PropTypes.string,
  title: PropTypes.string,
  user: PropTypes.object,
  editMode: PropTypes.bool,
  deletedUserModal: PropTypes.bool,
  userGlobalRole: PropTypes.object,
  teamMenu: PropTypes.bool,
  hidden: PropTypes.bool,
};

export default AddUser;
