import React from 'react';
import AppCard from '@crema/core/AppCard';
import {Box} from '@mui/material';
// import ProfilePic from 'assets/icon/avatar.svg';
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import DotsDropdownButton from './DotsDropdownButton/DotsDropdownButton';
import {Link} from 'react-router-dom';
import styles from './SalesforceCard.module.css';
import {
  GET_CUR_ORG_ID,
  GET_CUR_ORG_NAME,
  SALESFORCE_RESPONSE,
} from 'shared/constants/ActionTypes';
import {useDispatch} from 'react-redux';
import sfcc from 'assets/images/Dashboard/sfcc.svg';
import securityGatePic from 'assets/images/salesforce/continoussecurity.svg';
import gitlab from 'assets/images/salesforce/gitlab.svg';
import devops from 'assets/images/salesforce/devops.svg';
import salesforceOrg from 'assets/images/salesforce/salesforce-org.svg';
import {useSelector} from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import jira from 'assets/images/salesforce/jira.svg';
import github from 'assets/images/salesforce/github.svg';
import bitbucket from 'assets/images/salesforce/bitbucket.svg';
import {
  differenceInHours,
  differenceInMinutes,
  differenceInDays,
} from 'date-fns';
import ScanCustomTags from 'components/dashboard/DashboardCharts/Reports/ScanCustomTags/ScanCustomTags';

function SalesforceCard(props) {
  const {onCleanFields} = props;
  // const loggedUser = useSelector(({common}) => common.userInfo);
  const dispatch = useDispatch();
  const updateOrgId = () => {
    dispatch({type: GET_CUR_ORG_ID, payload: props.data._id});
    dispatch({type: GET_CUR_ORG_NAME, payload: props.data.name});
  };

  const subscriptionActive = useSelector(
    ({common}) => common.subscriptionActive,
  );

  //filtered tags for workspaces
  let customFieldsIds = props.data.tags ? props.data.tags : [];
  const allTags = useSelector(({tags}) => tags.allTagsData) || [];
  const filteredTags = Array.isArray(allTags) ? allTags.filter((tag) => customFieldsIds.some((customFieldId) => customFieldId === tag._id)) : [];

  const subscriptionStatus = () => {
    dispatch({
      type: SALESFORCE_RESPONSE,
      payload: {
        status: 'fail',
        message:
          'Valid subscription is required to view results. Please contact sales@digitsec.com to purchase a license.',
      },
    });
  };

  const showDayTimeDiff = (lastScan) => {
    let today = new Date();
    let diffDay = differenceInDays(today, new Date(lastScan));
    let diffHour = differenceInHours(today, new Date(lastScan));
    let diffMin = differenceInMinutes(today, new Date(lastScan));

    if (diffDay > 1) {
      return diffDay + ' Days ago';
    } else if (diffHour > 1) {
      return diffHour + ' Hours ago';
    } else if (diffMin > 1) {
      return diffMin + ' Mins ago';
    } else if (diffMin < 1) {
      return '1 Min Ago';
    }
  };

     //tags permissions
     const loggedUser = useSelector(({common}) => common.userInfo);
     const allRoles = useSelector(({roles}) => roles.getAllRoles);
     const allPermissions = useSelector(({roles}) => roles.getAllPermissions);
 
     const currentOrg = loggedUser.usr.salesforceOrgs.find(
       (org) => org.sfdcId === props.data._id,
     );
 
     if (!currentOrg) {
       console.error('Org not found');
       return null;
     }
 
     const roleForCurrentOrg = allRoles?.find(
       (role) => role._id === currentOrg.roleId,
     );
 
     if (!roleForCurrentOrg) {
       console.error('role not found');
       return null;
     }
 
     const permissionsForCurrentOrg = (allPermissions || []).filter(
       (permission) => permission._id === roleForCurrentOrg.permissionsId,
     );
 
     const isDisabledCreate = !permissionsForCurrentOrg
       .flatMap((permission) => permission.permissions)
       .some((permission) => permission.object === 'createTags');
 
 

  return (
    <>
      <AppCard
        sxStyle={{
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
        contentStyle={{pb: {xl: '3 !important'}}}
      >
        <Box
          sx={{
            px: {xs: 1, sm: 6, xl: 8},
            py: {xs: 2, sm: 3, xl: 4},
            position: 'relative',
          }}
        >
          <Box sx={{position: 'absolute', top: 1, right: 5}}>
            <DotsDropdownButton
              org_id={props.data._id}
              name={props.data.name}
              isSfcc={props.data.isSfcc}
              webdavurl={props.data.webdavurl}
              instanceurl={props.data.instanceurl}
              writeable={props.writeable}
              filteredTags={filteredTags}
              onCleanFields={onCleanFields}
            />
          </Box>

          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: {xs: 'column', sm: 'row'},
              justifyContent: 'flex-start',
              alignItems: 'center',
              mt: {xs: 5, md: 6, xl: 8},
            }}
          >
            <Link
              to={`/dashboard/${props.data._id}?tab=dashboard`}
              onClick={updateOrgId}
              className={styles.routeLink}
              title='Click to View Details'
            >
              <Box sx={{maxWidth: 170}}>
                <img
                  src={props.data.isSfcc == true ? sfcc : salesforceOrg}
                  style={{minHeight: 60, minWidth: 60}}
                  alt='Profile Pic'
                />
              </Box>
            </Link>

            <Box
              sx={{
                fontWeight: 600,
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
                padding: 5,
              }}
            >
              <Link
                to={`/dashboard/${props.data._id}?tab=dashboard`}
                onClick={updateOrgId}
                className={styles.routeLink}
                title='Click to View Details'
              >
                {!subscriptionActive ? (
                  <h1
                    className={styles.headingCard}
                    onClick={subscriptionStatus}
                  >
                    {props.data.name}
                  </h1>
                ) : (
                  <h1 className={styles.headingCard}>{props.data.name}</h1>
                )}
              </Link>
              <p className={styles.textCard}>{props.data.instanceurl}</p>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: {xs: 'space-around', sm: 'space-between'},
              alignItems: 'center',
              flexWrap: 'wrap',
              textAlign: 'center',
            }}
            className={styles.boxSalesforceDetail}
          >
            {/* <div className={styles.orgInfoContainer}>
                
              </div> */}
            {props.data?.sfdcid != null && (
              <div className={styles.orgInfoContainer}>
                <b>Salesforce ID: </b>
                <p>{props.data.sfdcid}</p>
              </div>
            )}
            {props.data?._id != null && (
              <div className={styles.orgInfoContainer}>
                <b>Workspace ID:</b>
                <p>{props.data._id}</p>
              </div>
            )}
            <div className={styles.lastScanDate}>
              Last Scanned: &nbsp; &nbsp;
              <p className={styles.dateStyle}>
                {props?.data?.Last_scanned
                  ? showDayTimeDiff(props?.data?.Last_scanned)
                  : 'Never Scanned'}
              </p>
            </div>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: {xs: 'space-around', sm: 'space-between'},
              alignItems: 'center',
              flexWrap: 'wrap',
              textAlign: 'center',
            }}
            className={styles.boxSalesforceDetail}
          >
            <div className={styles.lastScanType}>
              Last Scan Type:
              {props?.data?.Last_scan_type?.trim().replaceAll(' ', ', ') ? (
                <p className={styles.dateStyle}>
                  {props?.data?.Last_scan_type?.trim().replaceAll(' ', ', ')}
                </p>
              ) : (
                <p className={styles.typeTags}></p>
              )}
            </div>
          </Box>

          <div>
            <div className={styles.customTags}>
              {filteredTags?.length > 0 &&
                filteredTags.map((item) => {
                  return (
                    <ScanCustomTags
                      key={item._id}
                      fieldName={item.key}
                      fieldValue={item.value}
                      href={item.url}
                      filteredTags={filteredTags}
                      tagId={item._id}
                      org_id={props.data._id}
                      isDisabledCreate={isDisabledCreate}
                      isDeleted={item.isDeleted}
                      onCleanFields={onCleanFields}
                    />
                  );
                })}
            </div>
          </div>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: {xs: 'space-around', sm: 'space-between'},
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
            className={styles.boxSalesforceImage}
          >
            <div className={styles.iconContainer}>
              {props.data.securityGateConfigs?.isEnabled && (
                <Tooltip title='Security Gate Configured'>
                  <img src={securityGatePic} className={styles.singleIcon} />
                </Tooltip>
              )}
              {props.data.repoInfo.hasGitLabRepo && (
                <Tooltip title='Gitlab Repo Integrated'>
                  <img className={styles.singleIcon} src={gitlab} />
                </Tooltip>
              )}
              {props.data.repoInfo.hasAzureRepo && (
                <Tooltip title='Azure Repo Integrated'>
                  <img className={styles.singleIcon} src={devops} />
                </Tooltip>
              )}
              {props.data.project_id && (
                <Tooltip title='Jira Repo Integrated'>
                  <img className={styles.singleIcon} src={jira} />
                </Tooltip>
              )}
              {props.data.hasGitHubRepo && (
                <Tooltip title='Github Repo Integrated'>
                  <img className={styles.singleIcon} src={github} />
                </Tooltip>
              )}
              {props.data.hasBitbucketRepo && (
                <Tooltip title='Bitbucket Repo Integrated'>
                  <img className={styles.singleIcon} src={bitbucket} />
                </Tooltip>
              )}
            </div>
            <div className={styles.adminInformation}>
              <Link to=''>
                <Tooltip
                  title={props?.data?.addedBy ? props?.data?.addedBy : ''}
                >
                  <Avatar
                    style={{
                      alignSelf: 'end',
                      float: 'right',
                      margin: '4px',
                      padding: 0,
                      backgroundColor: '#' + Math.floor(Math.random() * 255),
                      fontSize: '0.75rem',
                    }}
                  >
                    {props?.data?.addedBy?.includes('@')
                      ? props?.data?.addedBy?.split('@')[0]?.split('')[0]
                        ? props?.data?.addedBy
                            ?.split('@')[0]
                            ?.split('')[0]
                            .toUpperCase()
                        : ''
                      : (props?.data?.addedBy?.split(' ')[0]?.split('')[0]
                          ? props?.data?.addedBy
                              ?.split(' ')[0]
                              ?.split('')[0]
                              .toUpperCase()
                          : '') +
                        (props?.data?.addedBy?.split(' ')[1]?.split('')[0]
                          ? props?.data?.addedBy
                              ?.split(' ')[1]
                              ?.split('')[0]
                              .toUpperCase()
                          : '')}
                  </Avatar>
                </Tooltip>
              </Link>
            </div>
          </Box>
        </Box>
      </AppCard>
    </>
  );
}

export default SalesforceCard;

SalesforceCard.propTypes = {
  data: PropTypes.any,
  status: PropTypes.any,
  writeable: PropTypes.bool,
  onCleanFields: PropTypes.func,
};
